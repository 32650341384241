<template>
  <div id="app" class="app" v-if="!isLoading">
    <router-view name="sidebar"></router-view>
    <router-view name="header"></router-view>
    <main class="main">
      <router-view class="view router-view"></router-view>
    </main>
    <router-view name="footer"></router-view>
  </div>
  <Loader v-else />
</template>

<script>
import OneSignalVue from 'onesignal-vue';
import Vue from "vue";
import Loader from "./components/Loader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Loader,
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt().then(r => {
      console.log('subscription prompt shown')
    }).catch(error => {
      console.log(error)
    })
    // window.OneSignal.setExternalUserId('0000');
    // this.$OneSignal.setExternalUserId('1111')
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", this.$i18n.locale);
    } else {
      this.$i18n.locale = localStorage.getItem("locale");
    }
    Vue.http.headers.common["X-Locale"] = this.$i18n.locale;
  },
  mounted() {
    this.$OneSignal.on('subscriptionChange', function(isSubscribed) {
      console.log("The user's subscription state is now:", isSubscribed);
    });
    // let script = document.createElement("script");
    // switch (this.$i18n.locale) {
    //   case "ru":
    //     script.src = 'https://code.jivo.ru/widget/rnjPA1lrwW"';
    //     break;
    //   case "kz":
    //     script.src = "https://code.jivo.ru/widget/2HhF5cuxvP";
    //     break;
    // }
    // script.defer = true;
    // script.async = true;
    // document.body.appendChild(script);
  },
};
</script>

<style lang="css">
html,
body {
  height: 100%;
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}
.router-view {
  transition: all 0.5s ease;
}
.router-enter,
.router-leave {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}
</style>
