<template>
  <component :is="tag" class="section">
    <div class="container">
      <slot />
    </div>
  </component>
</template>
<script>
export default {
  name: "Section",
  props: {
    tag: {
      type: String,
      default: "section",
    },
  },
};
</script>

<style scoped lang="scss">
.section {
  background: #f6f7f8;
  padding: 40px 0;
}
.container {
  max-width: 1168px;
  margin: 0 auto;
  padding: 0 15px;
}
</style>
