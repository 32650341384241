import Vue from 'vue'
import './assets/styles.scss'
import store from '@/store'
import router from '@/router'
import VuexRouterSync from 'vuex-router-sync'
import App from './App'
// import JQuery from 'jquery';
import ElementUi from 'element-ui';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import VueCarousel from 'vue-carousel';
import VueMoment from 'vue-moment';
import moment from 'moment';
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
// import Calendar from 'v-calendar/lib/components/calendar.umd'
import 'viewerjs/dist/viewer.css'
import 'normalize.css'
import OneSignalVue from 'onesignal-vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseInput from '@/components/BaseInput.vue'

// window.$ = JQuery;

import '@/plugins'
import '@/validators'
import '@/directives'
import '@/locales'
import '@/components'

Vue.config.productionTip = false

import '../public/css/fonts.css';
import '../public/css/main.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './registerServiceWorker'

VuexRouterSync.sync(store, router)
Vue.use(BootstrapVue)
Vue.use(ElementUi, {locale})
Vue.use(VueCarousel)
Vue.use(OneSignalVue);
Vue.use(moment)
// Vue.component('calendar', Calendar)
// Vue.component('date-picker', DatePicker)
Vue.component('BaseButton', BaseButton)
Vue.component('BaseInput', BaseInput)

Vue.filter('formatDate', function(value, format = 'DD.MM.YYYY') {
  if (value) {
    return moment(String(value)).format(format);
  }
});

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({ 
      appId: 'a1d55be1-30e7-4a9b-b8c2-cbeac0221fb2',
      allowLocalhostAsSecureOrigin: true, });
  },
}).$mount('#app')
