import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/FooterNew'

export default [
  {
    name: 'home',
    path: '/',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/NewHome/index')
    }
  },
  {
    name: 'signin',
    path: '/signin',
    component: () => import('@/views/Auth/SignIn')
  },
  {
    name: 'signup',
    path: '/signup',
    components: {
      default: () => import('@/views/Auth/Signup/SignUp')
    }
  },
  {
    path: '/confirm_email',
    component: () => import('@/views/Auth/Signup/ConfirmEmail.vue')
  },
  {
    name: 'forgotPassword',
    path: '/forgot-password',
    components: {
      default: () => import('@/views/Auth/ForgetPassword/Email')
    }
  },
  {
    name: 'resetPassword',
    path: '/reset_password',
    components: {
      default: () => import('@/views/Auth/ResetPassword')
    }
  },
  {
    name: 'about-company',
    path: '/about-company',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Home/About')
    },
  },
  {
    name: 'for-employers',
    path: '/for-employers',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Home/ForEmployers')
    },
  },
  {
    name: 'courses-list',
    path: '/courses-list',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Home/Index')
    }
  },
  {
    name: 'course-details',
    path: '/course/:slug',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/Home/Courses/Details')
    },
    props: true
  },
  {
    path: '/courses',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/NewCourses/index')
    }
  },
  {
    path: '/profile',
    component: () => import('@/views/Home/Courses'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('@/views/Home/Profile'),
        meta: { requiresAuth: true },
      },
      {
        path: 'courses',
        name: 'courses-home',
        component: () => import('@/views/Home/Courses/CoursesHome'),
        meta: { requiresAuth: true },
      },
      {
        path: 'interviews',
        name: 'interviews',
        component: () => import('@/views/Home/Interviews'),
        meta: { requiresAuth: true },
      },
      {
        path: 'courses/:slug',
        name: 'about-course',
        component: () => import('@/views/Home/Courses/Course/About'),
        meta: { requiresAuth: true },
      },
      {
        path: 'courses/:slug/module/:module',
        name: 'course-module',
        component: () => import('@/views/Home/Courses/Course/Module'),
        meta: { requiresAuth: true },
      },
      {
        name: 'course-theme',
        path: 'courses/:slug/module/:module/theme/:theme',
        component: () => import('@/views/Home/Courses/Theme'),
        meta: { requiresAuth: true },
      },
      // {
      //   path: 'courses/:slug/exercise/:quiz',
      //   name: 'course-exercise',
      //   component: () => import('@/views/Home/Courses/Course/CourseExercise'),
      //   meta: { requiresAuth: true },
      // },
      {
        name: 'course-review',
        path: '/course/:slug/review/:course',
        components: {
          header: Navbar,
          footer: Footer,
          default: () => import('@/views/old/Courses/CourseReview')
        }
      },
      {
        name: 'final-testing',
        path: '/course/:slug/final',
        components: {
          header: Navbar,
          footer: Footer,
          default: () => import('@/views/old/Courses/FinalTesting')
        }
      },
      {
        name: 'course-exercise',
        path: '/course/:slug/exercise/:quiz',
        components: {
          header: Navbar,
          footer: Footer,
          default: () => import('@/views/old/Courses/CourseExercise')
        }
      },
      {
        name: 'course-final-exercise',
        path: '/course/:slug/final-exercise/:quiz',
        components: {
          header: Navbar,
          footer: Footer,
          default: () => import('@/views/old/Courses/CourseFinalExercise')
        }
      },
    ]
  },
  // {
  //   name: 'final-testing',
  //   path: '/course/:slug/final',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/old/Courses/FinalTesting'),
  //     meta: { requiresAuth: true },
  //   }
  // },
  // {
  //   name: 'course-exercise',
  //   path: '/course/:slug/exercise/:quiz',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/old/Courses/CourseExercise'),
  //     meta: { requiresAuth: true },
  //   }
  // },
  // {
  //   name: 'course-final-exercise',
  //   path: '/course/:slug/final-exercise/:quiz',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/old/Courses/CourseFinalExercise'),
  //     meta: { requiresAuth: true },
  //   }
  // },
  /*
  {
    path: '/profile-old',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/ProfileStudent/index')
    }
  },
  {
    path: '/profile_settings/:section',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/ProfileStudent/Profile_settings')
    }
  },
  {
    path: '/profile_tutor',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/ProfileStudent/Tutor')
    }
  },
  {
    name: 'news',
    path: '/news',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/News/News')
    }
  },
  {
    name: 'news-item',
    path: '/news/:id',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/News/NewsItem')
    }
  },
  {
    name: 'courses-index',
    path: '/courses',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/Courses')
    }
  },
  {
    path: '/my_courses/active',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/MyCourses_active')
    }
  },
  {
    path: '/theme_one_video',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/ThemeOneVideo')
    }
  },
  {
    name: 'Presentation',
    path: '/presentation/:id',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/Presentation'),
    },
  },
  {
    path: '/theme_one_essay',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/ThemeOneEssay')
    }
  },
  {
    path: '/theme_one_exercises_good',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/ThemeOneExercisesGood')
    }
  },
  {
    path: '/theme_one_exercises_bad',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/ThemeOneExercisesBad')
    }
  },
  {
    path: '/module_testing_good',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/ModuleTestingGood')
    }
  },
  {
    path: '/module_testing_bad',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/ModuleTestingBad')
    }
  },
  {
    path: '/review',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/Review')
    }
  },
  {
    path: '/review_text',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/ReviewText')
    }
  },
  {
    path: '/final_testing_good',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/FinalTestingGood')
    }
  },
  {
    path: '/final_testing_bad',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/FinalTestingBad')
    }
  },
  {
    path: '/feedback',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/Feedback')
    }
  },
  // {
  //   name : 'notifications',
  //   path: '/notifications',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Courses/Notifications')
  //   }
  // },
  {
    name: 'notifications_new',
    path: '/notifications_new',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Courses/NotificationsNew')
    }
  },
  // {
  //   path: '/notifications_read',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Courses/NotificationsRead')
  //   }
  // },
  // {
  //   path: '/notifications_settings',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Courses/NotificationsSettings')
  //   }
  // },
  {
    path: '/faq',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/FAQ/FAQCategories')
    }
  },
  {
    name: 'faq-item',
    path: '/faq/:id',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/FAQ/FAQ')
    }
  },
  {
    path: '/contacts',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Contacts/Contacts')
    }
  },
  {
    path: '/store',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Store/Store')
    }
  },
  {
    path: '/store/basket',
    components: {
      header: Navbar,
      footer: Footer,
      default: () => import('@/views/old/Store/Basket')
    }
  },
  */
  // {
  //   path: '/store_one_product',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Store/StoreOneProduct')
  //   }
  // },
  // {
  //   name: 'goods-details',
  //   path: '/store/:id',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/old/Store/StoreOneProduct')
  //   }
  // },
  // {
  //   path: '/delivery',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/Store/Delivery')
  //   }
  // },
  // {
  //   path: '/my_orders',
  //   components: {
  //     header: Navbar,
  //     footer: Footer,
  //     default: () => import('@/views/old/Store/MyOrders')
  //   }
  // },
  {
    name: 'not-found',
    path: '*',
    components: {
      header: Navbar,
      footer: Footer,
      default: Empty
    }
  }
]
