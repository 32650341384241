import Vue from 'vue';
import i18n from '../../locales'

const COURSE_GET_REQUEST = 'COURSE_GET_REQUEST';
const COURSE_GET_SUCCESS = 'COURSE_GET_SUCCESS';
const COURSE_GET_FAIL = 'COURSE_GET_FAIL';

const COURSE_SET_MENU = 'COURSE_SET_MENU';

const state = {
  loading: false,
  menu: [],
  data: null,
}

const getters = {
  isCourseLoading(state) {
    return state.loading
  },
  getCourse(state) {
    return state.data;
  },
  getSideMenu(state) {
    return state.menu
  }
}

const mutations = {
  [COURSE_GET_REQUEST] (state) {
    state.loading = true;
  },
  [COURSE_GET_SUCCESS] (state, data) {
    state.data = data;
    state.loading = false;
  },
  [COURSE_GET_FAIL] (state, err) {
    state.course = null;
    state.loading = false;
  },
  [COURSE_SET_MENU] (state, data) {
    state.menu = [
      {
        name: 'О курсе', // i18n.t("About_course"),
        available: true,
        link: {
          name: 'about-course',
          params: {
            slug: data.slug
          }
        }
      },
    ]

    if (data.modules) {
      state.menu = [
        ...state.menu,
        ...data.modules.map((module, idx) => ({
          name: module.title,
          available: idx === 0 ? true : data.modules[idx - 1].themes.every(({ available }) => available),
          link: {
            name: 'course-module',
            params: {
              slug: data.slug,
              module: module.id
            }
          }
        }))
      ]
    }

    if (data.quiz) {
      this.menu.push({
        name: 'Итоговое тестирование', // i18n.t('Final_testing'),
        link: {
          name: 'course-final-exercise',
          params: {
            slug: data.slug,
            quiz: data.quiz.id
          }
        },
      })
    }
  }
}

const actions = {
  fetchCourse({commit}, slug) {
    commit(COURSE_GET_REQUEST)
    return Vue.http.get(`${API_ROOT}/api/course/${slug}`)
      .then((res) => {
        commit(COURSE_GET_SUCCESS, res.body)
        commit(COURSE_SET_MENU, res.body);
      })
      .catch((res) => {
        commit(COURSE_GET_FAIL)
        return Promise.reject(res)
      })
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
