<template>
    <button 
    class="btn"
    :style="{
        'width': width,
        'height': height
        }"
    v-on="$listeners"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        width: String,
        height: String,
    }
}
</script>

<style lang="scss" scoped>
.btn {
    background: #007BFC;
    border-radius: 10px;
    width: 300px;
    height: 65px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    transition: 0.3s ease;

    &:hover, &:focus {
        color: #fff;
        background: #0076F2;
        box-shadow: 0px 22px 44px -12px rgba(126, 193, 255, 0.35);
    }
}
</style>