<template>
  <Section class="footer" tag="footer">
    <div class="footer__inner">
      <a href="https://qazinn.kz/" class="footer__inner_logo" target="_blank">
        <img src="@/assets/images/logo-kazinnovations.webp" alt="kazinnovations" width="140" height="22" loading="lazy">
        <div>
          {{ $t("ao_qazinnovations") }}
        </div>
      </a>
      <a class="footer__email" href="mailto:djoon_76@mail.ru">
        DJOON_76@mail.ru
      </a>
      <div class="footer__docs">
        <a class="footer__custom-agreement" href="#" target="_blank">
          {{ $t("terms_of_use") }}
        </a>
        <a class="footer__policy-privacy" href="#" target="_blank">
          {{ $t("privacy_policy") }}
        </a>
        <div class="footer__copyright">DJOON © 2011-{{ new Date().getFullYear() }}</div>
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";

export default {
  name: "FooterNew",
  components: {Section},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.footer {
  padding: 21px 0 29px 0;
  color: #546272;
  border-top: 1px solid rgba(223, 223, 223, 0.46);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_logo {
      width: 140px;
      font-size: 10px;
      line-height: 1em;
      color: #000;
      text-decoration: none;

      img {
        max-width: 100%;
        margin-bottom: 4px;
      }

      div {
        margin-left: 30px;
      }
    }
  }

  &__email {
    color: inherit;
    position: relative;
    padding-left: 24px;
    margin-right: auto;
    margin-left: 20px;

    &::before {
      content: "";
      position: absolute;
      background: url("../assets/images/email.svg") no-repeat;
      width: 18px;
      height: 19px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &__docs {
    display: flex;
    align-items: center;

    > * {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }

  &__custom-agreement {
    color: inherit;
  }

  &__policy-privacy {
    color: inherit;
  }

  &__copyright {
    color: inherit;
  }
}

@media screen and (max-width: 992px) {
  .footer {
    &__inner {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    &__docs {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    &__email {
      margin-left: 0;
    }
  }
}
</style>
