<template>
  <Section class="header" tag="header">
    <div class="header__inner">
      <router-link to="/" class="header__logo">
        <img
          src="@/assets/images/djoon_logo.svg"
          width="122"
          height="34"
          alt="Logo"
        />
      </router-link>
      <nav class="header__menu">
        <router-link
          class="header__menu-link"
          :to="to"
          v-for="({ name, to }, index) in menuLinks"
          :key="index"
        >
          {{ name }}
        </router-link>
      </nav>

      <div class="dropdown_menu">
        <div class="dropbtn_menu" @click-outside="outsideClick" @click="insideClick">{{ locale }}</div>
        <div class="dropdown_content" v-if="contentVisible">
          <a @click="switchLocale('ru')" href="#">Русский</a>
          <a @click="switchLocale('kz')" href="#">Қазақша</a>
        </div>
      </div>

      <div class="header__auth header_user" v-if="!logged">
        <router-link to="/signup" class="header__btn-sign-up" type="button">Регистрация</router-link>
        <router-link to="/signin" class="header__btn-sign-in" type="button">
          <img src="@/assets/images/user.svg" alt="user">
          Войти
        </router-link>
      </div>
      <div class="navbar_profile_dropdown_menu header_user" v-else>
        <button class="sign_in_profile" @click-outside="outside" @click="inside">
          <div :style="getImage()" class="profile_avatar"></div>
          <div class="profile_info">
            <div class="profile_name">{{ user.email }}</div>
            <div class="profile_role">{{ user.iin }}</div>
          </div>
        </button>
        <div v-show="showProfileMenu" class="navbar_profile_dropdown_content">
          <div class="navbar_profile_dropdown_back">
            <router-link class="navbar__item profile" :to="{ name: 'profile' }">{{ $t('Profile') }}</router-link>
            <router-link class="navbar__item courses" :to="{ name: 'courses-home' }">{{
                $t('My_courses')
              }}
            </router-link>
            <router-link class="navbar__item interviews" :to="{ name: 'interviews' }">{{ $t('Interviews') }}
            </router-link>
            <div class="navbar_profile_dropdown_border"/>
            <div class="navbar__item logout" @click="logoutMethod">{{ $t('logout') }}</div>
          </div>
        </div>
      </div>
      <div class="header_burger" @click="showHidden = !showHidden">
        <div class="header_burger_item"></div>
        <div class="header_burger_item"></div>
        <div class="header_burger_item"></div>
      </div>
    </div>
    <div class="header_hidden" :class="{'active': showHidden}">
      <div class="header_hidden_top">
        <router-link to="/" class="header__logo">
          <img
            src="@/assets/images/djoon_logo.svg"
            width="122"
            height="34"
            alt="Logo"
          />
        </router-link>
        <div class="header_burger hidden" @click="showHidden = !showHidden">
          <div class="header_burger_item"></div>
          <div class="header_burger_item"></div>
          <div class="header_burger_item"></div>
        </div>
      </div>
      <div class="header_hidden_body">
        <nav class="header__menu d-block" @click="showHidden = false">
          <router-link
            class="header__menu-link"
            :to="to"
            v-for="({ name, to }, index) in menuLinks"
            :key="index"
          >
            {{ name }}
          </router-link>
        </nav>
      </div>
      <div class="header_hidden_bottom">
        <div class="header__auth" v-if="!logged">
          <router-link to="/signup" class="header__btn-sign-up" type="button">Регистрация</router-link>
          <router-link to="/signin" class="header__btn-sign-in" type="button">
            <img src="@/assets/images/user.svg" alt="user">
            Войти
          </router-link>
        </div>
      </div>
    </div>
  </Section>
</template>
<script>
import Section from "@/components/Section.vue";
import {mapGetters} from "vuex";

export default {
  name: "NavbarNew",
  components: {Section},
  data() {
    return {
      showHidden: false,
      locale: localStorage.getItem('locale'),
      contentVisible: false,
      showProfileMenu: false,
      menuLinks: [
        {
          name: this.$t('header.main'),
          to: "/",
        },
        {
          name: this.$t('header.courses'),
          to: "/courses-list",
        },
        {
          name: this.$t('header.for_employers'),
          to: "/for-employers",
        },
        {
          name: this.$t('header.about_company'),
          to: "/about-company",
        },
      ],
    };
  },
  methods: {
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      //this.$router.go(this.$router.currentRoute)
      location.reload();
    },
    outsideClick: function () {
      this.contentVisible = false
    },
    insideClick: function () {
      this.contentVisible = !this.contentVisible
    },
    outside: function () {
      this.showProfileMenu = false
    },
    inside: function () {
      this.showProfileMenu = !this.showProfileMenu
    },
    getImage() {
      // const path = 'https://api.djoon.kz/images' + this.user.avatar
      const path = this.user.avatar || 'avatars/Avatar-1.svg'
      return `background-image: url('${path}')`;
    },
    logoutMethod() {
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      localStorage.removeItem('basket_id');
      localStorage.removeItem('coins');
      window.location = '/';
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    user: function () {
      return this.getUser ? JSON.parse(this.getUser) : null
    },
    logged: function () {
      let user = localStorage.getItem('user')
      let token = localStorage.getItem('token')
      return token !== null && token !== '' && user !== null && user !== ''
    },
  },
  mounted() {
  }
};
</script>

<style scoped lang="scss">
.header {
  padding: 16px 0;
  background: rgba(255, 255, 255, 0.43);
  backdrop-filter: blur(4px);
  position: relative;
  z-index: 2;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__logo {
  }

  &__auth {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    display: flex;
    gap: 12px;
  }

  &__btn-sign-up {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #0b1f35;
    border: 1px solid #B6BCC3;
    padding: 6px 16px;
    border-radius: 4px;
    background: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__btn-sign-in {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #fff;
    background: #007BFC;
    border: 1px solid #007BFC;
    padding: 6px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
      text-decoration: none;
    }
  }

  &__menu {
    margin: auto;
  }

  &__menu-link {
    color: #0b1f35;
    transition: color 0.2s ease-out;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 36px;
    }

    &:hover {
      color: #007bfc;
    }

    .router-link-exact-active {
      color: #007bfc;
    }
  }

  &_burger {
    display: none;
    flex-direction: column;
    width: 24px;
    gap: 6px;
    margin-left: 16px;
    position: relative;
    cursor: pointer;

    &_item {
      width: 100%;
      background: #0B1F35;
      height: 2px;
      border-radius: 2px;
    }

    &.hidden {
      .header_burger_item {
        position: absolute;
        top: 0;
        transform: rotate(45deg);

        &:nth-child(2) {
          transform: rotate(-45deg);
        }

        &:nth-child(3) {
          display: none;
        }
      }
    }
  }


  .profile_avatar {
    background-color: #F6F7F8;
  }

  .sign_in_profile::after {
    display: none;
  }

  &_hidden {
    background: #fff;
    position: fixed;
    z-index: 150;
    width: 100%;
    height: 100vh;
    top: 0;
    right: -100%;
    padding: 0 15px;
    transition: 0.3s ease;
    opacity: 0;

    &.active {
      right: 0;
      opacity: 1;
    }

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
    }

    &_body {
      .header__menu {
        &-link {
          display: block;
          margin-bottom: 16px;
        }
      }
    }

    &_bottom {
      padding-top: 20px;
    }
  }
}

.dropdown_menu {
  margin-right: 30px;

  .dropdown_content a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.navbar_profile_dropdown_menu {
  margin-left: 0;
}

.header__menu-link.router-link-exact-active {
  color: #007bfc;
}

@media screen and (max-width: 1020px) {
  .header {
    &__menu {
      display: none;
    }

    &_burger {
      display: flex;
    }

    &_user.header__auth {
      display: none;
    }
  }
  .dropdown_menu {
    margin-left: auto;
    margin-right: 16px;
  }
}

@media screen and (max-width: 720px) {
  .navbar_profile_dropdown_content .navbar_profile_dropdown_back {
    position: absolute;
    right: 0;
    width: 200px;
  }
}

@media screen and (max-width: 578px) {
  .header__auth {
    flex-direction: column;
    gap: 12px;
    text-align: center;
  }
  .profile_info {
    display: none;
  }
  .sign_in_profile::after {
    display: none;
  }
}
</style>
