<template>
    <div 
        class="base-input" 
        :class="{'onerror': onerror, 'active': value}"
    >
        <!-- <label>
            {{ placeholder }}
        </label> -->
        <input 
            :type="type ? type : 'text'" 
            :placeholder="placeholder" 
            v-on:input="updateValue($event.target.value)"
        >
        <div class="icon" v-if="isPassword" @click="togglePassword()">
            <img v-if="type === 'password'" src="@/assets/images/EyeClosed.svg" alt="eye-open">
            <img v-else src="@/assets/images/eye-open.svg" alt="eye-open">
        </div>
        <div class="error">
            {{ onerror }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseInput',
    props: {
        placeholder: String,
        onerror: String,
        value: String | Number,
        type: String,
        isPassword: Boolean
    },
    data() {
        return {
            inputValue: ''
        }
    },
    methods: {
        updateValue(val) {
            this.$emit('input', val)
        },
        togglePassword() {
            this.$emit('togglePassword')
        }
    }
}
</script>

<style lang="scss" scoped>
.base-input {
    position: relative;
    width: 410px;
    max-width: 100%;
    margin: 0 auto 12px;

    label, .error {
        display: block;
        font-size: 12px;
        color: #546272;
        position: absolute;
        top: -20px;
        opacity: 0;
    }
    .error {
        text-align: left;
        color: #FF3737;
        position: static;
        margin-top: 5px;
        max-height: 0;
        opacity: 1;
        transition: 0.3s ease;
    }
    input {
        background: #fff;
        height: 60px;
        width: 100%;
        max-width: 100%;
        padding-left: 16px;
        font-size: 14px;
        border: 1px solid #E9EAEA;
        border-radius: 8px;
        color: #0B1F35;
        outline: none;
    }

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-62%);
        right: 16px;
        width: 24px;
        height: 24px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &:focus, &.active {
        label {
            opacity: 1;
        }
        input {
            border-color: #546272;
        }
    }

    &.onerror {
        input {
            border-color: #FF3737;
        }
        .error {
            max-height: 20px;
        }
    }
}
</style>